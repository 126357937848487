.swiper {
    max-height: 900px !important;
}

.main-slider {
    margin-bottom: 50px !important;
}

.swiper-button-next {
    right: var(--swiper-navigation-sides-offset, -20px) !important;
    z-index: 1 !important;
}

.swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, -20px) !important;
    z-index: 1 !important;
}

.main-slider .swiper-horizontal, 
.product-review .swiper-horizontal {
    padding-bottom: 35px !important;
    margin-bottom: 25px !important;
}

.our-passion .swiper-horizontal {
    padding-bottom: 55px !important;
    margin-bottom: 25px !important;
}

.product-review .swiper-button-prev:after, 
.product-review .swiper-button-next:after {
    color: #A3C202 !important;
}

.our-history {
    height: 88vh;
    max-height: 500vh;
}

.our-passion .swiper-pagination-bullet {
    width: 40px !important;
    height: 20px;
    text-align: center !important;
    line-height: 20px !important;
    font-size: 12px !important;
    color: #000 !important;
    opacity: 1 !important;
    background: unset !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    color: #ababab !important;
}

.our-passion .swiper-pagination-bullet-active {
    color: #4f7fa7 !important;
    font-weight: 600 !important;
}

.our-passion .swiper-pagination {
    bottom: 2px !important;
}

.our-history .swiper-pagination-bullet {
    width: 140px !important;
    height: 20px;
    text-align: center !important;
    line-height: 20px !important;
    font-size: 12px !important;
    color: #000 !important;
    opacity: 1 !important;
    background: unset !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 24px !important;
    color: #ababab !important;
    margin: 40px 0 !important;
}

.our-history .swiper-pagination-bullet-active {
    color: #4f7fa7 !important;
    font-weight: 600 !important;
}

.our-history
    .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-vertical
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    width: 140px !important;
    right: var(--swiper-pagination-right, -5px) !important;
}

.swiper-pagination-bullet {
    width: 40px !important;
    height: 4px !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    background: #ababab !important;
}

.swiper-pagination-bullet-active {
    background: #4f7fa7 !important;
}

.subheader {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%) !important;
}

@media screen and (max-width: 1024px) {
    .our-history {
        max-height: unset !important;
    }

    .our-history .swiper-pagination-bullet {
        margin: unset !important;
        top: 0 !important;
    }

    .our-history .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, -30px) !important;
    }
}

@media screen and (max-width: 600px) {
    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }

    .our-history .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom, -35px) !important;
    }

    .swiper-pagination-clickable {
        height: 55px !important;
    }
}
